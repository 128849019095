<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createUserObjects"
          append
          class="btn btn-add btn_color_green"
          >Добавить пользователя</router-link
        >
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="users"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUsersObject from "@/helpers/tableCols/UserObject";
// import moment from "moment";
export default {
  name: "ObjectsUsers",
  data() {
    return {
      loadingBtn: false,
      queries: query.objectUsers
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      let preHeader = tableColsUsersObject.filter(
        el =>
          ![
            "ID",
            "Дилеров",
            "Компаний",
            "Объектов",
            "Контроллеров",
            "Сумма всех платежей по объектам дилера",
            "Карта",
            "Приложение",
            "Выбранная группа пользователей",
            "Доступные группы пользователей",
            "Сумма всех платежей",
            "Дата добавления в систему"
          ].includes(el.text)
      );

      // preHeader.$insert(7, {
      //   text: "Номер л/c",
      //   renderFunction: value => {
      //     return value.user_accounts?.reduce((firstValue, secondValue) => {
      //       firstValue += secondValue + "</br>";
      //       return firstValue;
      //     }, "");
      //   },
      //   userRole: [30, 60, 70]
      // });
      // preHeader.$insert(8, {
      //   text: "Последняя дата оплаты",
      //   // value: "last_payment_date",
      //   renderFunction: value =>
      //     value.last_payment_date
      //       ? moment(value.last_payment_date * 1000).format("DD.MM.YYYY")
      //       : "-",
      //   userRole: [30, 60, 70]
      // });
      // preHeader.$insert(9, {
      //   text: "Оплачено до",
      //   renderFunction: value =>
      //     `<div><span style="${
      //       value.paid_until
      //         ? "color: #95C23D!important;"
      //         : "color: #EB5C6D!important;"
      //     }">${
      //     value.paid_until
      //         ? moment(value.paid_until * 1000).format("DD.MM.YYYY")
      //         : "Не оплачено"
      //     }</span></div>`,
      //   userRole: [30, 60, 70]
      // });

      // preHeader.$insert(9, {
      //   text: "Оплачено до",
      //   renderFunction: value => {
      //     const access = value.actual_accesses;
      //     if (access && access.length > 0) {
      //       return access.reduce((res, access) => {
      //         res += `<div><span>+</span></br><span style="color: #95C23D!important;">Оплачено до ${moment(
      //           access.date_end * 1000
      //         ).format("DD MM YYYY")}</span></div>`;
      //         return res;
      //       }, "");
      //     } else {
      //       return `<div><span>-</span></br><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
      //     }
      //   },
      //   userRole: [30, 60, 70]
      // });
      //
      // preHeader.$insert(9, {
      //   text: "Оплачено до",
      //   renderFunction: value => {
      //     let access = value.actual_accesses; // выбранные группы
      //     if (access.length >= 2) {
      //       for (let i = 0; i < access.length; i++) {
      //         if (access[i].access_type == "app") {
      //           access = [access[i]];
      //         }
      //       }
      //     }
      //
      //     if (access && access.length > 0) {
      //       return access.reduce((res, access) => {
      //         if (access.access_by == "paid" && access.access_type == "app") {
      //           res += `<div  style="color: #95C23D!important;">${moment(
      //             access.date_end * 1000
      //           ).format("DD.MM.YYYY")}</div>`;
      //           return String(res);
      //         } else if (
      //           access.access_by == "trial" &&
      //           access.access_type == "app"
      //         ) {
      //           res += `<div style="color: #95C23D!important;">Пробный, ${moment(
      //             access.date_end * 1000
      //           ).format("DD.MM.YYYY")}</div>`;
      //           return res;
      //         } else if (
      //           access.access_by == "unpaid" &&
      //           access.access_type == "app"
      //         ) {
      //           res += `<div style="color: #95C23D!important;">Бесплатный доступ</div>`;
      //           return res;
      //         } else {
      //           return `<div><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
      //         }
      //       }, "");
      //     } else {
      //       return `<div><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
      //     }
      //   },
      //   userRole: [30, 60, 70]
      // });
      preHeader.$insert(10, {
        text: "Выбранная группа пользователей",
        value: "actual_accesses",
        renderFunction: value => {
          const access = value.actual_accesses;
          if (access && access.length > 0) {
            return access.reduce((res, access) => {
              res += access.group_title + "<br/>";
              return res;
            }, "");
          } else {
            return "-";
          }
        },
        userRole: [30, 60, 70]
      });
      preHeader.$insert(11, {
        text: "Доступные группы пользователей",
        renderFunction: value => {
          const access = value.access_groups;
          if (access && access.length > 0) {
            return access.reduce((res, access) => {
              res += access.title + "<br/>";
              return res;
            }, "");
          } else {
            return "-";
          }
        },
        userRole: [30, 60, 70]
      });

      preHeader.$insert(12, {
        text: "Сумма всех платежей",
        value: "paid_for_object",
        userRole: [30, 60, 70]
      });

      return preHeader;
    },
    sourceObject() {
      return this.$store.getters.getObjectUsersList;
    },
    urlQuery() {
      return {
        id: this.$route.params.id,
        query: this.$route.query
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadObjectsUsersList", this.header).then(() => {
        this.loadingBtn = false;
      });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        groups__object: this.urlQuery.id,
        ...this.urlQuery.query,
        object: this.urlQuery.id
      },
      id: this.urlQuery.id
      // type: this.type
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchObjectUsersList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchObjectUsersList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUsersPage").then(() => next());
  }
};
</script>

<style scoped></style>
