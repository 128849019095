<template>
  <div>
    <v-dialog v-model="downloadPopup" max-width="600">
      <v-card class="pa-8">
        <div class="object__item">
          <div class="title center-align mb-4">Выберите период</div>
          <div class="d-flex justify-space-between">
            <div class="ma-2">
              <DatePicker v-model="moment_gte" label="Дата начала периода" />
            </div>
            <div class="ma-2">
              <DatePicker v-model="moment_lte" label="Дата окончания периода" />
            </div>
          </div>
        </div>
        <v-card-actions class="mt-4 d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn class="btn btn_color_white" @click="downloadPopup = false">
            ОТМЕНА
          </v-btn>
          <v-btn
            :loading="dialog"
            :disabled="
              !this.moment_gte ||
                !this.moment_lte ||
                this.moment_gte > this.moment_lte
            "
            class="btn btn_color_green"
            @click="dowloadPeriodPassLog()"
          >
            ПРОДОЛЖИТЬ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" hide-overlay persistent width="600" height="400">
      <v-card color="#95c23d" dark>
        <v-card-text>
          <div class="title title-loading">
            Ожидайте выгрузки файла, который будет доступен в разделе меню
            <router-link style="color: #FFFFFF" :to="{ name: 'Profile' }" exact
              >"Профиль"</router-link
            >
          </div>

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      :table-data="sourceObject.results"
      :source-object="sourceObject"
      :header="header"
      to="users"
      :passlogsUserId="true"
    />
  </div>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import DatePicker from "@/components/fields/DatePicker";

import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import tableColsPassageLogObject from "@/helpers/tableCols/passageLogObject";
import moment from "moment/moment";
export default {
  name: "ObjectPassageLog",
  components: { TableLayout, TableFilters, DatePicker },
  data() {
    return {
      loadingBtn: false,
      queries: query.passLogObject,
      downloadPopup: false,
      dialog: false,
      objectId: null, // id объекта ДС
      moment_lte: null, // конец периода
      moment_gte: null // начало периода
    };
  },
  computed: {
    header() {
      return tableColsPassageLogObject;
    },
    sourceObject() {
      return this.$store.getters.getPassLog;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id,
        moment__gte: moment()
          .startOf("day")
          .unix(),
        moment__lte: moment()
          .endOf("day")
          .unix()
      };
    }
  },
  methods: {
    downloadList() {
      if (this.sourceObject.count > 1000) {
        this.downloadPopup = true;
      } else {
        this.loadingBtn = true;

        this.$store.dispatch("downloadPassLog", this.header).then(() => {
          this.loadingBtn = false;
        });
      }
    },

    dowloadPeriodPassLog() {
      this.dialog = true;
      this.downloadPopup = false;

      let data = {
        moment_lte: moment(this.moment_lte)
          .add(moment().utcOffset(), "minutes")
          .unix(),
        moment_gte: moment(this.moment_gte)
          .add(moment().utcOffset(), "minutes")
          .unix(),
        id: null
      };
      this.$store.commit("setUserGroupId", localStorage.getItem("groupId"));

      this.$store
        .dispatch("fetchOrganizationObjectsList") // вызываем чтобы получить id объекта ДС
        .then(() => {
          this.$events.$emit("clear_date-picker");
          this.objectId = this.$store.getters.getPageQuery?.id;
        })
        .then(() => {
          data.id = this.objectId;
          this.$store.dispatch("downloadReportsPassLog", { data }).then(() => {
            setTimeout(() => (this.dialog = false), 5000);
          });
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        controller__object: this.urlQuery.id,
        moment__gte: moment()
          .startOf("day")
          .unix(),
        moment__lte: moment()
          .endOf("day")
          .unix()
      }
    });
    this.$store.commit("SET_VALUE_SORT", "moment");
    this.isDateLoaded = true;
    this.$store.dispatch("fetchObjectPassLog").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            moment__gte: this.urlQuery.query.moment__gte
              ? this.urlQuery.query.moment__gte
              : moment()
                  .startOf("day")
                  .unix(),
            moment__lte: this.urlQuery.query.moment__lte
              ? this.urlQuery.query.moment__lte
              : moment()
                  .endOf("day")
                  .unix()
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchObjectPassLog").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearControllersPage").then(() => next());
  }
};
</script>

<style scoped></style>
