const slideTabsObjects = [
  {
    text: "Общая информация",
    value: "info",
    icon: "i",
    // count: null,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Лицевые счета",
    value: "personal_accounts",
    icon: null,
    userRole: [10, 20, 30, 60, 70]
  },

  {
    text: "Контроллеры",
    value: "controllers",
    icon: null,
    // count: 348,
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Карты",
    value: "physical_cards",
    icon: null,
    userRole: [40, 50],
    showKindergarten: true
  },
  {
    text: "Группы пользователей",
    value: "users_group",
    icon: null,
    // count: 18,
    userRole: [10, 20, 30, 60, 70]
  },
  {
    text: "Пользователи",
    value: "users",
    icon: null,
    // count: 4568,
    userRole: [10, 20, 30, 60, 70]
  },
  {
    text: "Журнал проходов",
    value: "pass_log",
    icon: null,
    count: null,
    userRole: [10, 20, 30, 60, 70]
  },
  {
    text: "Платежи",
    value: "payments",
    icon: null,
    userRole: [10, 20, 30, 60, 70]
  }
];

export default slideTabsObjects;
