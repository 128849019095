<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:filter-title>
        <span v-if="sourceObject.count">
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          <!--          <span>групп пользователей</span>-->
        </span>
      </template>
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createUserGroup"
          append
          class="btn btn-add btn_color_green"
          >Добавить группу пользователей</router-link
        >
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="userGroups"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUserGroup from "@/helpers/tableCols/UserGroup";

export default {
  name: "ObjectsUsersGroups",
  data() {
    return {
      loadingBtn: false,
      queries: query.groups.filter(
        el =>
          el.type !== "object__city" &&
          el.type !== "organization__diller" &&
          el.type !== "organization" &&
          el.type !== "object"
      )
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsUserGroup.filter(
        el =>
          el.text !== "Объект" &&
          el.text !== "Компания" &&
          el.text !== "Дилер" &&
          el.value !== "city"
      );
    },
    sourceObject() {
      return this.$store.getters.getObjectGroupList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadObjectsUsersGroupList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, object: this.urlQuery.id }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchObjectUserGroupList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            object: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchObjectUserGroupList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearGroupsPage").then(() => next());
  }
};
</script>

<style scoped></style>
r
