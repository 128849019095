import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";
import { getAdditionalFields } from "@/helpers/Constants/additional_fields";

const tableColsPassageLogObject = [
  {
    text: "Дата",
    value: "moment",
    renderFunction: value => moment(value?.moment * 1000).format("DD.MM.YYYY"),
    sort: true
  },
  {
    text: "Время",
    value: "time",
    renderFunction: value => moment(value?.moment * 1000).format("HH:mm:ss")
  },
  {
    text: "Тип доступа",
    value: "by_card",
    renderFunction: value =>
      value?.by_card
        ? `Карта, ${value?.card_code}`
        : value?.direction_translate === "Свободные руки"
        ? "Свободные руки"
        : "Приложение",
    sort: true
  },

  {
    text: "Телефон",
    // value: "teachers"
    renderFunction: value => {
      return phoneFilter.execute(value.user_details?.phone_number);
    }
  },
  {
    text: "Пользователь",
    renderFunction: value => {
      return getAdditionalFields(
        value?.user_details?.additional_fields,
        "Пользователь"
      );
    }
  },
  {
    text: "Дом/корпус",
    renderFunction: value => {
      return getAdditionalFields(
        value?.user_details?.additional_fields,
        "Дом/корпус"
      );
    }
  },
  {
    text: "Квартира/офис",
    renderFunction: value => {
      return getAdditionalFields(
        value?.user_details?.additional_fields,
        "Квартира/офис"
      );
    }
  },
  {
    text: "Номер авто",
    renderFunction: value => {
      return getAdditionalFields(
        value?.user_details?.additional_fields,
        "Номер авто"
      );
    }
  },

  {
    text: "Группа пользователей",
    value: "data__access_group_title",
    renderFunction: value => value.data?.access_group_title || "",
    sort: true
  },

  {
    text: "Название контроллера",
    value: "controller_details",
    renderFunction: value => value?.controller_details?.name
  },
  {
    text: "ID контроллера",
    value: "controller",
    renderFunction: value => value?.controller_details?.id,
    sort: true
  },
  {
    text: "Тип контроллера",
    value: "controllersType",
    renderFunction: value =>
      dictionariesHelper.controllersType[value?.controller_details?.type]
  }
];

export default tableColsPassageLogObject;
