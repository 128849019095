<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          v-if="
            $store.getters.getCurrentRole !== 40 &&
              $store.getters.getCurrentRole !== 50
          "
          to="createController"
          append
          class="btn btn-add btn_color_green"
          >Добавить контроллер</router-link
        >
      </template>
    </TableFilters>
    <TableLayout
      :table-data="sourceObject.results"
      to="controllers"
      :header="header"
      :source-object="sourceObject"
      :restore-button="isRestore"
    >
      <template v-slot:restore-td-father="{ itemRestore }">
        <button @click="clickRestore(itemRestore)" class="btn-restore btn">
          Восстановить
        </button>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsControllers from "@/helpers/tableCols/controllers";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "ObjectsControllers",
  data() {
    return {
      loadingBtn: false,
      queries: query.controllers.filter(
        el =>
          el.type !== "object__organization__diller" &&
          el.type !== "object__organization" &&
          el.type !== "object__service_organization" &&
          el.type !== "object"
      ),
      isRestore: false
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    sourceObject() {
      return this.$store.getters.getObjectOrganizationControllersList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    header() {
      return tableColsControllers.filter(el => {
        return (
          el.userRole.includes(this.$store.getters.getCurrentRole) &&
          el.value !== "object_address" &&
          el.value !== "object_name" &&
          el.value !== "organization_name" &&
          el.text !== "Дилер"
        );
      });
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadObjectControllersList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    },
    clickRestore(item) {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreController", { id: item.id, data: item })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.controller)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchObjectOrganizationControllersList");
        })
        .finally(() => {
          this.isDateLoaded = false;
        });
    }
  },
  mounted() {
    if (this.urlQuery.query?.is_deleted == "true") {
      this.isRestore = true;
    } else {
      this.isRestore = false;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, object: this.urlQuery.id }
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchObjectOrganizationControllersList")
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        if (to.query?.is_deleted == "true") {
          this.isRestore = true;
        } else {
          this.isRestore = false;
        }
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store
          .dispatch("fetchObjectOrganizationControllersList")
          .finally(() => {
            this.isDateLoaded = false;
          });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearControllersPage").then(() => next());
  }
};
</script>

<style scoped></style>
