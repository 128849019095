<template>
  <div class="">
    <div>
      <TableFilters :title-count="sourceObject.count" :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          </span>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        disable-router
        :table-data="sourceObject.results"
        :header="header"
        :source-object="sourceObject"
        dropDown
      >
      </TableLayout>
    </div>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsCards from "@/helpers/tableCols/cards";

export default {
  name: "KindergartensCards",
  data() {
    return {
      loadingBtn: false,
      dialog: false, // диалоговое окно popup удаления
      numCard: "", // номер карты
      idCard: "", // id карты
      downloadPopup: false,
      downloadDialog: false,

      queries: query.cards.filter(
        el =>
          el.type !== "object__organization__diller" &&
          el.type !== "organization_type" &&
          el.type !== "object__organization" &&
          el.type !== "activation-period-start" &&
          el.type !== "activation-period-end" &&
          el.type !== "organizations"
      )
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsCards.filter(
        el =>
          el.text !== "Детский сад" &&
          el.text !== "Дилер" &&
          el.text !== "Пользователь"
      );
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    sourceObject() {
      return this.$store.getters.getOrganizationCardsList;
    },

    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {},
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        object__organization: this.$store.getters.getObjectInfo.results
          .organization
      },
      id: this.$store.getters.getObjectInfo.results.organization
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchObjectCardsList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            object__organization: this.$store.getters.getObjectInfo.results
              .organization
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchObjectCardsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearCardsPage").then(() => next());
  // }
};
</script>

<style>
.checkbox-type .v-input__control .v-input__slot {
  height: 0px;
  top: 5px;
}
</style>
