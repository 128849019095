var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([{key:"block",fn:function(){return [_vm._v(" "+_vm._s(_vm.sourceObject.results ? _vm.sourceObject.results.is_active === false ? "Заблокирован -" : "" : "")+" ")]},proxy:true},{key:"navbar-title",fn:function(){return [_vm._v(_vm._s(_vm.sourceObject.results ? _vm.sourceObject.results.name : ""))]},proxy:true},{key:"navbar-btn-wrap",fn:function(){return [(
        _vm.sourceObject.results &&
          _vm.sourceObject.results.is_active &&
          _vm.$store.getters.getCurrentRole !== 50 &&
          _vm.$store.getters.getCurrentRole !== 40
      )?_c('BlockPopup',{attrs:{"name":"Объект","title":_vm.sourceObject.results ? _vm.sourceObject.results.name : '',"block-function":_vm.banObject}}):_vm._e(),(
        !(_vm.sourceObject.results && _vm.sourceObject.results.is_active) &&
          _vm.$store.getters.getCurrentRole !== 50 &&
          _vm.$store.getters.getCurrentRole !== 40
      )?_c('UnBlockPopup',{attrs:{"name":"Объект","title":_vm.sourceObject.results ? _vm.sourceObject.results.name : '',"un-block-function":_vm.unBlockObject}}):_vm._e(),(
        _vm.$store.getters.getCurrentRole !== 40 &&
          _vm.$store.getters.getCurrentRole !== 50
      )?_c('DeletePopup',{attrs:{"name":"Объект","title":_vm.sourceObject.results ? _vm.sourceObject.results.name : '',"delete-function":_vm.deleteObject}}):_vm._e(),(
        _vm.urlQuery &&
          _vm.urlQuery.id &&
          _vm.$store.getters.getCurrentRole !== 40 &&
          _vm.$store.getters.getCurrentRole !== 50
      )?_c('router-link',{staticClass:"btn btn-edit btn_color_grey",attrs:{"to":`/objectsEdit/` + _vm.urlQuery.id}},[_vm._v("редактировать")]):_vm._e()]},proxy:true}])},[_c('SlideLayout',{attrs:{"tabs":_vm.slideTabs},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('InfoLayout',{attrs:{"tabs":_vm.infoTabs},scopedSlots:_vm._u([{key:"object_data",fn:function(){return [_c('div',{staticClass:"keep__title"},[_c('p',{staticClass:"keep__text"},[_vm._v("Данные объекта")])]),(_vm.sourceObject.results)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.objectInfo},scopedSlots:_vm._u([{key:"item.4.value",fn:function({ item, keyName, value }){return [_c('div',{staticClass:"d-flex flex-row align-center justify-center"},[_c('div',[_vm._v(" "+_vm._s(value)+" ")]),_c('v-spacer'),(
                    _vm.$store.getters.getCurrentRole !== 40 &&
                      _vm.$store.getters.getCurrentRole !== 50
                  )?_c('div',[_c('v-switch',{attrs:{"input-value":_vm.sourceObject.results.accounts_enabled,"color":"#95c23d","inset":"","label":_vm.sourceObject.results.accounts_enabled
                        ? 'Включено'
                        : 'Выключено'},on:{"change":_vm.changeAccount}})],1):_vm._e()],1)]}},{key:"item.5.value",fn:function({ item, keyName, value }){return [_c('div',{staticClass:"d-flex flex-row align-center justify-center"},[(
                    _vm.$store.getters.getCurrentRole !== 60 ||
                      (_vm.$store.getters.getCurrentRole !== 70 &&
                        _vm.sourceObject.results.type !== 'kindergarten')
                  )?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),_c('v-spacer'),(
                    _vm.$store.getters.getCurrentRole === 60 ||
                      (_vm.$store.getters.getCurrentRole === 70 &&
                        _vm.sourceObject.results.type !== 'kindergarten')
                  )?_c('div',[_c('v-switch',{attrs:{"input-value":_vm.sourceObject.results.guest_access_allowed,"color":"#95c23d","inset":"","label":_vm.sourceObject.results.guest_access_allowed
                        ? 'Включено'
                        : 'Выключено'},on:{"change":_vm.changeGuestAccessAllowed}})],1):_vm._e()],1)]}}],null,false,3494923377)}):_vm._e()]},proxy:true}])})]},proxy:true},{key:"personal_accounts",fn:function(){return [_c('ObjectsAccounts')]},proxy:true},{key:"controllers",fn:function(){return [_c('ObjectsControllers')]},proxy:true},{key:"physical_cards",fn:function(){return [_c('ObjectsCards')]},proxy:true},{key:"users_group",fn:function(){return [_c('ObjectsUsersGroup')]},proxy:true},{key:"users",fn:function(){return [_c('ObjectsUsers')]},proxy:true},{key:"pass_log",fn:function(){return [_c('ObjectPassageLog')]},proxy:true},{key:"payments",fn:function(){return [_c('ObjectsPayments')]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }